// Copyright Northcote Technology Ltd
import idbReady from 'safari-14-idb-fix'
import { openDB } from 'idb'
import { sortBy } from 'lodash'

import { updateOfflineSessions } from './sessions'
import { IDB_NAME, IDB_VERSION } from 'src/init/idb'

const BUCKET_COMMON_PEOPLE = { table: 'common', index: 'people' }
const BUCKET_CURRENT_PERSON = { table: 'common', index: 'current_person' }
const BUCKET_DEFAULT_FIRST_NAME = {
  table: 'common',
  index: 'default_first_name',
}
const BUCKET_DEFAULT_LAST_NAME = { table: 'common', index: 'default_last_name' }
const BUCKET_GROUP_OPTIONS = { table: 'common', index: 'group_options' }
const BUCKET_PERSON_OPTIONS = { table: 'common', index: 'person_options' }
const BUCKET_RESULTS = { table: '/admin/results/' }
const BUCKET_SESSIONS = { table: 'sessions' }
const BUCKET_SESSIONS_DISPLAY = { table: 'common', index: 'sessions_display' }
const BUCKET_TEMPLATE_OPTIONS = { table: 'common', index: 'template_options' }
const BUCKET_TEMPLATE_SAMPLES = { table: 'template_session_samples' }

export const BUCKET_GRADINGS = { table: '/admin/gradings/' }
export const BUCKET_RECORDINGS = { table: '/admin/recordings/' }
export const BUCKET_SESSION_CHANGES = { table: '/admin/sessions/' }

export async function getDb() {
  await idbReady()
  return await openDB(IDB_NAME, IDB_VERSION)
}

export async function saveIdb(tableName, params) {
  const actionOnServer = params.action_server ? params.action_server : 'save'

  const db = await getDb()
  const promise = db.add(tableName, params)
  promise.then(id => {
    params.id = id
    updateOfflineSessions(actionOnServer, tableName, params)
  })
  return promise
}

export async function updateIdb(tableName, params) {
  const actionOnServer = params.action_server ? params.action_server : 'update'

  await updateOfflineSessions(actionOnServer, tableName, params)
  const db = await getDb()
  return db.put(tableName, params)
}

export async function deleteIdb(tableName, key, updateSessions = true) {
  if (updateSessions) await updateOfflineSessions('delete', tableName, key)
  const db = await getDb()
  return db.delete(tableName, key)
}

export async function getAll(tableName) {
  const db = await getDb()
  return db.getAll(tableName)
}

export async function getIdb(tableName, dataToSearch) {
  const db = await getDb()
  return db.get(tableName, dataToSearch)
}

export async function clearIdb(tableName) {
  const db = await getDb()

  return db.clear(tableName)
}

export async function clearAll() {
  const db = await getDb()

  Object.values(db.objectStoreNames).forEach(async tableName => {
    await db.clear(tableName)
  })
  return true
}

export async function getInfo(bucket, index = null) {
  index = index || bucket.index
  let result = {}
  if (index) result = await getIdb(bucket.table, index)
  else result = await getAll(bucket.table)
  return result?.data || result
}

export async function setInfo(bucket, data) {
  const info = bucket.index ? { key: bucket.index, data: data } : data
  await updateIdb(bucket.table, info)
}

export async function clearInfo(bucket, index = null) {
  index = index || bucket.index
  if (index) await deleteIdb(bucket.table, index)
  else await clearIdb(bucket.table)
}

export function clearGroupOptions() {
  return clearInfo(BUCKET_GROUP_OPTIONS)
}

export function clearSession(id) {
  return clearInfo(BUCKET_SESSIONS, id)
}

export function clearSessions() {
  return clearInfo(BUCKET_SESSIONS)
}

export function clearSessionsDisplay() {
  return clearInfo(BUCKET_SESSIONS_DISPLAY)
}

export function clearTemplateSamples() {
  return clearInfo(BUCKET_TEMPLATE_SAMPLES)
}

export function deleteGrading(key) {
  return deleteIdb(BUCKET_GRADINGS.table, key)
}

export function deleteResult(key) {
  return deleteIdb(BUCKET_RESULTS.table, key)
}

export function deleteSession(key) {
  return deleteIdb(BUCKET_SESSIONS.table, key)
}

export function getCurrentPerson() {
  return getInfo(BUCKET_CURRENT_PERSON)
}

export function getGradings() {
  return getInfo(BUCKET_GRADINGS)
}

export function getGroupOptions() {
  return getInfo(BUCKET_GROUP_OPTIONS)
}

export function getPeople() {
  return getInfo(BUCKET_COMMON_PEOPLE)
}

export function getPersonOptions() {
  return getInfo(BUCKET_PERSON_OPTIONS)
}

export function getRecordings() {
  return getInfo(BUCKET_RECORDINGS)
}

export function getResult(key) {
  return getInfo(BUCKET_RESULTS, key)
}

export function getSession(key) {
  return getInfo(BUCKET_SESSIONS, key)
}

export function getSessionChanges(key) {
  return getInfo(BUCKET_SESSION_CHANGES, key)
}

export function getSessions() {
  return getInfo(BUCKET_SESSIONS)
}

export function getSessionsDisplay() {
  return getInfo(BUCKET_SESSIONS_DISPLAY)
}

export function getTemplateOptions() {
  return getInfo(BUCKET_TEMPLATE_OPTIONS)
}

export function getTemplateSample(key) {
  return getInfo(BUCKET_TEMPLATE_SAMPLES, key)
}

export function getTemplateSamples() {
  return getInfo(BUCKET_TEMPLATE_SAMPLES)
}

export function setCurrentPerson(data) {
  return setInfo(BUCKET_CURRENT_PERSON, data)
}

export function setPersonOptions(data) {
  return setInfo(BUCKET_PERSON_OPTIONS, data)
}

export function setGroupOptions(groupOptions) {
  return setInfo(BUCKET_GROUP_OPTIONS, groupOptions)
}

export function setPeople(people) {
  const sortedPeople = sortBy(people, ['firstNames', 'lastNames'])
  return setInfo(BUCKET_COMMON_PEOPLE, sortedPeople)
}

export async function setPeopleInfo(data) {
  await setInfo(BUCKET_DEFAULT_FIRST_NAME, data.defaultFirstName)
  await setInfo(BUCKET_DEFAULT_LAST_NAME, data.defaultlAstName)
  await setCurrentPerson(data.currentPerson)
  await setPersonOptions(data.options)
}

export function setResult(data) {
  return setInfo(BUCKET_RESULTS, data)
}

export function setSession(data) {
  return setInfo(BUCKET_SESSIONS, data)
}

export function setSessionChanges(data) {
  return setInfo(BUCKET_SESSION_CHANGES, data)
}

export function setSessionsDisplay(sessions) {
  return setInfo(BUCKET_SESSIONS_DISPLAY, sessions)
}

export function setTemplateOptions(templateOptions) {
  return setInfo(BUCKET_TEMPLATE_OPTIONS, templateOptions)
}

export function setTemplateSample(data) {
  return setInfo(BUCKET_TEMPLATE_SAMPLES, data)
}
