// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { EntitiesSelector } from '../common/EntitiesSelector'
import { isPersonInGroups } from '../../src/lib/peopleHelper'

export default class GroupPeopleSelector extends Component {
  static propTypes = {
    groupOptions: PropTypes.object,
    peopleOptions: PropTypes.object,
    onUpdate: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentPeopleOptions: [],
    }
  }

  onGroupSelect = event => {
    const { peopleOptions, onUpdate } = this.props

    const groupsSelected = [event.target.value]
    let currentPeopleOptions = []

    if (groupsSelected) {
      currentPeopleOptions = { ...peopleOptions }
      currentPeopleOptions.values = peopleOptions.values.filter(p =>
        isPersonInGroups(p, groupsSelected)
      )
    }

    this.setState({ currentPeopleOptions })
    onUpdate(event)
  }

  onPeopleUpdate = event => {
    const { onUpdate } = this.props
    onUpdate(event)
  }

  render() {
    const { groupOptions } = this.props

    const { currentPeopleOptions } = this.state

    return (
      <Fragment>
        <EntitiesSelector
          mainClass={'quantum-layout__field'}
          prefixClass={'select'}
          currentValues={[]}
          info={groupOptions}
          onUpdate={this.onGroupSelect}
        />

        {currentPeopleOptions.values.length > 0 && (
          <EntitiesSelector
            mainClass={'quantum-layout__field'}
            prefixClass={'select'}
            currentValues={[]}
            info={currentPeopleOptions}
            onUpdate={this.onPeopleUpdate}
          />
        )}
      </Fragment>
    )
  }
}
