// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import areObjectsUniform from '../../src/lib/areObjectsUniform'
import mergeObjects from '../../src/lib/mergeObjects'
import gradingScaleProps from '../../proptypes/gradingScaleProps'
import GradeInputWrapper from './GradeInputWrapper'
import { getIdb } from '../../src/lib/idb/common'
import { gradingErrors } from 'src/lib/validations/gradingValidations'
import lodash from 'lodash'

const areGradingsUniform = gradings =>
  areObjectsUniform(gradings, ['grade', 'remaining'])

export default class GradeInputGroup extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    gradingScale: PropTypes.shape(gradingScaleProps).isRequired,
    gradings: PropTypes.arrayOf(
      PropTypes.shape({
        activityId: PropTypes.number,
        additionalInfo: PropTypes.object.isRequired,
        documentId: PropTypes.string,
        fileUploadName: PropTypes.string,
        grade: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        qualificationId: PropTypes.number.isRequired,
        repeated: PropTypes.bool.isRequired,
        applicableUntilDate: PropTypes.string,
        applicableFromDate: PropTypes.string,
        observations: PropTypes.array.isRequired,
        counter: PropTypes.number,
        firstAttempt: PropTypes.string,
      }).isRequired
    ).isRequired,
    incompleteMode: PropTypes.bool.isRequired,
    deferable: PropTypes.bool.isRequired,
    onGradings: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    gradingSession: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      alwaysShowCurrentGradings: !areGradingsUniform(props.gradings),
      errors: null,
    }
  }

  componentDidMount() {
    this.getErrors()
  }

  getErrors = async () => {
    const { gradingScale, gradingSession, gradings, translations } = this.props

    let gradingsErrors = []

    const result = await getIdb('unsubmitted_sessions', gradingSession.id)
    if (result) {
      gradings.forEach(grading => {
        gradingsErrors.push(gradingErrors(translations, gradingScale, grading))
      })
      let compactMessages = lodash.compact(
        lodash.uniq(lodash.flatten(gradingsErrors))
      )[0]
      this.setState({ errors: compactMessages })
    }
  }

  handleGradings(gradings) {
    const {
      gradingScale: { hasApplicableFromDate },
      gradingSession,
      onGradings,
    } = this.props

    this.getErrors()

    gradings.forEach(grading => {
      if (!grading.applicableFromDate && hasApplicableFromDate) {
        grading.applicableFromDate = gradingSession.gradedDate
      }
    })

    onGradings(gradings)
  }

  handleGroupIncomplete = incomplete => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      incomplete,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateGrading = newGrading => {
    const newGradings = this.props.gradings.map(grading =>
      grading.qualificationId === newGrading.qualificationId
        ? newGrading
        : grading
    )

    this.handleGradings(newGradings)
  }

  onUpdateGroupAdditionalInfo = additionalInfo => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      additionalInfo,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateGroupBehaviourIds = observations => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      observations,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateApplicableFromDate = applicableFromDate => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      applicableFromDate,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateApplicableUntilDate = applicableUntilDate => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      applicableUntilDate,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateGroupGrade = (grade, category) => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      category,
      grade,
    }))

    this.handleGradings(newGradings)
  }

  onUpdateGroupRepeated = repeated => {
    const newGradings = this.props.gradings.map(grading => {
      const { firstAttempt, grade } = grading

      return {
        ...grading,
        firstAttempt: repeated ? grade : null,
        grade: repeated ? null : firstAttempt,
        repeated,
      }
    })

    this.handleGradings(newGradings)
  }

  onUpdateGroupDeferred = deferred => {
    const newGradings = this.props.gradings.map(grading => {
      return {
        ...grading,
        deferred,
      }
    })

    this.handleGradings(newGradings)
  }

  onUpdateGroupDocuments = file => {
    const newGradings = this.props.gradings.map(grading => {
      const newFiles = grading.files.concat(file)

      return {
        ...grading,
        document: newFiles
          .filter(({ _destroy }) => !_destroy)
          .map(({ id }) => id),
        files: newFiles,
      }
    })

    this.handleGradings(newGradings)
  }

  onRemoveGroupDocument = fileToRemove => {
    const newGradings = this.props.gradings.map(grading => {
      const newFiles = grading.files.map(file =>
        file.id === fileToRemove.id ? { ...file, _destroy: true } : file
      )

      return {
        ...grading,
        document: newFiles
          .filter(({ _destroy }) => !_destroy)
          .map(({ id }) => id),
        files: newFiles,
      }
    })

    this.handleGradings(newGradings)
  }

  onToggleCurrentGradings = event => {
    event.preventDefault()

    if (areGradingsUniform(this.props.gradings)) {
      event.currentTarget.classList.toggle('icon--angle-left')
      event.currentTarget.classList.toggle('icon--angle-down')

      this.setState({
        alwaysShowCurrentGradings: !this.state.alwaysShowCurrentGradings,
      })
    }
  }

  onCounterUpdate = counter => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      counter,
    }))

    this.handleGradings(newGradings)
  }

  showCurrentGradings() {
    return (
      this.state.alwaysShowCurrentGradings ||
      !areGradingsUniform(this.props.gradings)
    )
  }

  onLastAttemptUpdate = (grade, category) => {
    const newGradings = this.props.gradings.map(grading => ({
      ...grading,
      category,
      grade,
    }))

    this.handleGradings(newGradings)
  }

  render() {
    const {
      directUploadsUrl,
      gradingScale,
      gradings,
      incompleteMode,
      online,
      translations,
      gradingSession,
      deferable,
    } = this.props

    const groupGrading = mergeObjects(gradings)
    groupGrading['additionalInfo'] = groupGrading['additionalInfo'] || {}
    groupGrading.observations = gradings[0].observations

    const { qualificationsData } = gradingSession

    return (
      <div
        className={classNames({
          'grade-input-group': true,
          'grade-input-group--show-gradings': this.showCurrentGradings(),
        })}
      >
        <div className="grade-input-group__group-grading">
          {Object.keys(groupGrading).length > 0 ? (
            <GradeInputWrapper
              directUploadsUrl={directUploadsUrl}
              grading={groupGrading}
              deferable={deferable}
              gradingScale={gradingScale}
              incompleteMode={incompleteMode}
              onAdditionalInfoUpdate={this.onUpdateGroupAdditionalInfo}
              onGradeUpdate={this.onUpdateGroupGrade}
              onIncompleteUpdate={this.handleGroupIncomplete}
              onRepeatedUpdate={this.onUpdateGroupRepeated}
              onDeferredUpdate={this.onUpdateGroupDeferred}
              onDocumentsUpdate={this.onUpdateGroupDocuments}
              onDocumentRemove={this.onRemoveGroupDocument}
              onApplicableFromDateUpdate={this.onUpdateApplicableFromDate}
              onApplicableUntilDateUpdate={this.onUpdateApplicableUntilDate}
              onBehaviourIdsUpdate={this.onUpdateGroupBehaviourIds}
              onToggleCurrentGradings={this.onToggleCurrentGradings}
              onCounterUpdate={this.onCounterUpdate}
              online={online}
              translations={translations}
              gradingSession={gradingSession}
              errorsByGradings={this.state.errors}
              onLastAttemptUpdate={this.onLastAttemptUpdate}
            />
          ) : null}
        </div>

        {groupGrading.incomplete ? null : (
          <div className="grade-input-group__gradings">
            {gradings.map(currentGrading => {
              const qualification =
                qualificationsData[currentGrading.qualificationId]

              return (
                <div key={currentGrading.qualificationId}>
                  <GradeInputWrapper
                    directUploadsUrl={directUploadsUrl}
                    grading={currentGrading}
                    deferable={deferable}
                    gradingScale={gradingScale}
                    hidden={true}
                    label={qualification.name}
                    onUpdate={this.onUpdateGrading}
                    online={online}
                    translations={translations}
                    gradingSession={gradingSession}
                    hideFirstAttempt={true}
                  />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
