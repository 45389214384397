// Copyright Northcote Technology Ltd
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import nameToId from 'src/lib/nameToId'
import DynamicSelect from './DynamicSelect'

export default class SessionTemplatePicker extends Component {
  static propTypes = {
    sessionTemplates: PropTypes.array.isRequired,
    sessionTemplateName: PropTypes.string.isRequired,
    sessionTemplateNoResultsText: PropTypes.string.isRequired,
    sessionTemplatePlaceholderText: PropTypes.string.isRequired,
    sessionTemplateLabel: PropTypes.string,
    styleVersion: PropTypes.number,
    newUi: PropTypes.bool,
    onUpdate: PropTypes.func,
    mainClass: PropTypes.string,
    labelClass: PropTypes.string,
    selectClass: PropTypes.string,
    prefixClass: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentValue: null,
    }
  }

  handleSelectSessionTemplate = event => {
    this.setState({
      currentValue: event.target.value,
    })
    this.onChange(event)
  }

  onChange = event => {
    const { onUpdate } = this.props

    if (onUpdate) onUpdate(event)
  }

  render() {
    const {
      sessionTemplates,
      sessionTemplateLabel,
      sessionTemplateName,
      sessionTemplatePlaceholderText,
      sessionTemplateNoResultsText,
      newUi,
      mainClass,
      labelClass,
      selectClass,
      prefixClass,
      styleVersion,
    } = this.props

    let Tag = styleVersion == 2 ? 'div' : 'label'

    return newUi ? (
      <Fragment>
        <div className={mainClass}>
          <Tag className={labelClass} htmlFor={nameToId(sessionTemplateName)}>
            {sessionTemplateLabel}
          </Tag>
          <div className={selectClass}>
            <DynamicSelect
              clearable={true}
              name={sessionTemplateName}
              currentValue={this.state.currentValue}
              noResultsText={sessionTemplateNoResultsText}
              onSelect={this.handleSelectSessionTemplate}
              placeholderText={sessionTemplatePlaceholderText}
              values={sessionTemplates}
              styleVersion={styleVersion}
              prefixClass={prefixClass}
            />
          </div>
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <label htmlFor={nameToId(sessionTemplateName)}>
          {sessionTemplateLabel}
        </label>
        <DynamicSelect
          clearable={true}
          name={sessionTemplateName}
          currentValue={this.state.currentValue}
          noResultsText={sessionTemplateNoResultsText}
          onSelect={this.handleSelectSessionTemplate}
          placeholderText={sessionTemplatePlaceholderText}
          values={sessionTemplates}
          styleVersion={styleVersion}
          prefixClass={prefixClass}
        />
      </Fragment>
    )
  }
}
