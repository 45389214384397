// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { EntitiesSelector } from '../common/EntitiesSelector'

export default class NewPersonModal extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    errors: PropTypes.object,
    groupParams: PropTypes.object.isRequired,
    currentGroupId: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      person: {
        firstName: '',
        lastName: '',
        identifier: '',
        group_ids: [props.currentGroupId],
      },
    }
  }

  handleInputChange = event => {
    if (!event) return

    const { person } = this.state

    const target = event.target
    const value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    let newPerson = person
    newPerson[name] = value

    this.setState({ person: newPerson })
    this.props.onUpdate(newPerson)
  }

  inputField = (label, field) => {
    const { errors, groupParams } = this.props
    const { person } = this.state
    const error = errors ? errors[field] : null
    const value = person[field]
    if (field == 'group_ids') {
      return (
        <EntitiesSelector
          mainClass="quantum-layout__field-50"
          prefixClass="select"
          labelClass={'quantum-layout__label p0'}
          currentValues={[]}
          info={groupParams}
          onUpdate={this.handleInputChange}
          key={groupParams['id']}
          selectClass={'quantum-layout__select'}
          error={error ? [errors[field]] : null}
        />
      )
    } else {
      return (
        <div className="quantum-layout__field-50" key={field}>
          <span className="quantum-layout__label p0">
            {label + (field == 'identifier' ? ' *' : '')}
          </span>
          {error ? (
            <div className="quantum-layout__field--error">{error}</div>
          ) : (
            ''
          )}
          <input
            key={field}
            type="text"
            name={field}
            placeholder={name}
            value={value || ''}
            onChange={this.handleInputChange}
          />
        </div>
      )
    }
  }

  showGroup = () => {
    const { currentGroupId, groupParams, translations } = this.props
    const currentGroupName = groupParams.values.find(
      v => v.value == currentGroupId.toString()
    )?.label

    return (
      <div className="quantum-layout__field-50">
        <div className="quantum-layout__label p0">
          {translations.activerecord.attributes.sessions_for_grader.group}
        </div>
        <div className="quantum-layout__value p0">
          <div className="quantum-layout__tag">{currentGroupName}</div>
        </div>
      </div>
    )
  }

  render() {
    const { groupParams, translations } = this.props
    const attr = translations.activerecord.attributes.person

    return (
      <div className="sessions_modal">
        {this.inputField(attr.identifier, 'identifier')}
        {this.inputField(attr.first_names, 'firstName')}
        {this.inputField(attr.last_names, 'lastName')}
        {UBF.personGroupsMandatory
          ? this.showGroup()
          : this.inputField(groupParams.label, 'group_ids')}
      </div>
    )
  }
}
