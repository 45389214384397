// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import partitionActivityAndSessionGradings from '../../src/lib/partitionActivityAndSessionGradings'
import translate from '../../src/lib/translate'

import IconAction from '../IconAction'
import StandaloneGradeInput from './StandaloneGradeInput'
import OverallGradingResult from '../sessions_for_grader/OverallGradingResult'
import OverallGrading from '../sessions_for_grader/OverallGrading'
import GradeInputGroup from './GradeInputGroup'
import AutoExpandingTextarea from '../AutoExpandingTextarea'

class GradingForm extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    incompleteMode: PropTypes.bool.isRequired,
    onGradings: PropTypes.func.isRequired,
    online: PropTypes.bool.isRequired,
    result: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
    translations: PropTypes.object.isRequired,
    onUpdateAbstract: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      abstract: false,
      currentAbstract: props.result.abstract || '',
    }
  }

  handleGradings = gradings => {
    const { onGradings } = this.props
    onGradings(gradings)
  }

  showAbstract = () => {
    this.setState({ abstract: !this.state.abstract })
  }

  updateCurrentAbstract = evt => {
    this.setState({ currentAbstract: evt.currentTarget.value })
  }

  updateAbstract = async () => {
    const { currentAbstract } = this.state
    const { result, session, onUpdateAbstract } = this.props

    if (onUpdateAbstract)
      onUpdateAbstract(session.id, result.id, currentAbstract)
  }

  render() {
    const {
      directUploadsUrl,
      incompleteMode,
      online,
      result,
      session,
      translations,
    } = this.props
    const {
      activitiesData,
      gradingScalesData,
      gradingSessionTemplate: { overallGrading },
      qualificationsData,
    } = session

    const [gradeByActivityGradings, gradeBySessionGradings] =
      partitionActivityAndSessionGradings(result.gradings, session)

    const session_gradings = gradeBySessionGradings.map(grading => {
      const { qualificationId, incoming } = grading
      const qualification = qualificationsData[qualificationId]
      const gradingScale = gradingScalesData[qualification.gradingScaleId]
      const deferable = gradingScale.deferable && !result.lastInCourse
      const incomingTag = incoming
        ? ` (${translate(
            'messages.gradingSessions.incomingFromPrevious',
            translations
          )})`
        : ''

      return (
        <StandaloneGradeInput
          directUploadsUrl={directUploadsUrl}
          grading={grading}
          gradingScale={gradingScale}
          incompleteMode={incompleteMode}
          key={`q${qualificationId}`}
          label={qualification.name + incomingTag}
          onGrading={grading => this.handleGradings([grading])}
          online={online}
          translations={translations}
          gradingSession={session}
          deferable={deferable}
        />
      )
    })

    const activity_gradings = gradeByActivityGradings.map(gradings => {
      const { activityId, incoming, qualificationId } = gradings[0]
      const activity = activitiesData[activityId]
      const qualification = qualificationsData[qualificationId]
      const gradingScale = gradingScalesData[qualification.gradingScaleId]
      const deferable = gradingScale.deferable && !result.lastInCourse
      const incomingTag = incoming
        ? ` (${translate(
            'messages.gradingSessions.incomingFromPrevious',
            translations
          )})`
        : ''

      return (
        <div key={`a${activityId}`}>
          <label className="label-tag--orca">
            {activity.name + incomingTag}
          </label>

          <GradeInputGroup
            directUploadsUrl={directUploadsUrl}
            gradings={gradings}
            gradingScale={gradingScale}
            incompleteMode={incompleteMode}
            label={qualification.name}
            onGradings={this.handleGradings}
            online={online}
            translations={translations}
            gradingSession={session}
            deferable={deferable}
          />
        </div>
      )
    })

    return (
      <section className="gradings-form">
        <div className="orca-abstract">
          <div className="orca-abstract__header">
            <label className="label-tag--orca" htmlFor="abstract">
              {translate(
                'activerecord.attributes.gradingSessionResult.abstract',
                translations
              )}
            </label>
            <IconAction
              color={this.state.currentAbstract !== '' ? 'green' : null}
              icon="plus-circle"
              inline={true}
              onClick={this.showAbstract}
            >
              {translations.forms.inputValues.gradingForm.buttons.comment}
            </IconAction>
          </div>
          {this.state.abstract ? (
            <AutoExpandingTextarea
              onChange={this.updateCurrentAbstract}
              onBlur={this.updateAbstract}
              value={this.state.currentAbstract}
              id="abstract"
            />
          ) : null}
        </div>
        {activity_gradings}
        {session_gradings}
        {overallGrading ? (
          <OverallGrading
            gradingSessionResult={result}
            options={overallGrading.overallGradingOptions}
            translations={translations}
          />
        ) : null}
        <OverallGradingResult
          gradingSessionResult={result}
          translations={translations}
        />
      </section>
    )
  }
}

export default GradingForm
