// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { difference } from 'lodash'

import DynamicSelectWithState from '../DynamicSelectWithState'

export class EntitiesSelector extends Component {
  static propTypes = {
    async: PropTypes.bool,
    info: PropTypes.shape({
      values: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      noResultsText: PropTypes.string.isRequired,
      placeholderText: PropTypes.string.isRequired,
      multi: PropTypes.bool,
      sorted: PropTypes.bool,
    }),
    onUpdate: PropTypes.func,
    currentValues: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    mainClass: PropTypes.string,
    prefixClass: PropTypes.string,
    labelClass: PropTypes.string,
    selectClass: PropTypes.string,
    styleVersion: PropTypes.number,
    error: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentValues: this.props.currentValues || [],
    }
  }

  onSelect = event => {
    const { onUpdate, info } = this.props
    const { currentValues } = this.state

    const lastSelected = difference(event.target.value, currentValues)
    const filteredValues =
      lastSelected.length == 0 && info.sorted
        ? currentValues.filter(e => event.target.value.includes(e))
        : currentValues
    onUpdate(event)
    this.setState({
      currentValues: info.sorted
        ? filteredValues.concat(lastSelected)
        : event.target.value,
    })
  }

  render() {
    const {
      values,
      label,
      name,
      placeholderText,
      noResultsText,
      multi,
      sorted,
    } = this.props.info
    const {
      async,
      prefixClass,
      mainClass,
      labelClass,
      selectClass,
      styleVersion,
      error,
    } = this.props
    const { currentValues } = this.state

    var sortedValues = values
    if (sorted && currentValues.length > 0) {
      sortedValues = values.sort(function (a, b) {
        return (
          [...currentValues].indexOf(a.value) -
          [...currentValues].indexOf(b.value)
        )
      })
    }

    return (
      <div className={mainClass}>
        <div className={labelClass}>{label}</div>
        {error ? (
          <div className="quantum-layout__field--error">{error.join(', ')}</div>
        ) : (
          ''
        )}
        <div className={selectClass}>
          <DynamicSelectWithState
            async={async}
            clearable={!multi}
            currentValue={this.state.currentValues}
            multi={multi}
            name={name}
            noResultsText={noResultsText}
            onUpdate={this.onSelect}
            placeholderText={placeholderText}
            prefixClass={prefixClass}
            styleVersion={styleVersion || 2}
            values={sortedValues}
          />
        </div>
      </div>
    )
  }
}
