// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFlexLayoutStore, sessionsSelector } from '../../redux/selectors'
import translate from 'src/lib/translate'
import QuantumLayout from '../QuantumLayout'
import Index from './Index'
import LoadSession from './LoadSession'
import NotificationModal from '../common/NotificationModal'
import OnlineWatcher from '../OnlineWatcher'
import Summary from './Summary'
import Recordings from '../Recordings'
import GradingFormsGroup from '../next/GradingFormsGroup'
import Timeline from '../Timeline'
import camelize from 'camelize'
import moment from 'moment'
import OfflinePin from '../common/OfflinePin'
import { FooterButton } from '../Footer'
import { saveSession } from '../../redux/app'
import { onlineSelector } from '../../redux/selectors'

class Module extends Component {
  static propTypes = {
    directUploadsUrl: PropTypes.string.isRequired,
    online: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    backUrl: PropTypes.string,
    userPin: PropTypes.string,
    pinEnabled: PropTypes.bool,
    groupOptions: PropTypes.array,
    flexlayout: PropTypes.object,
    saveSession: PropTypes.func.isRequired,
    sessions: PropTypes.object.isRequired,
    showCreatePerson: PropTypes.bool,
    showNewSession: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      incompleteMode: false,
      sessionId: null,
      view: 'index',
    }
  }

  goTo(view) {
    this.setState({ incompleteMode: false, view })
  }

  factory = node => {
    const {
      directUploadsUrl,
      translations,
      groupOptions,
      online,
      showCreatePerson,
      showNewSession,
    } = this.props
    const { sessionId } = this.state

    switch (node.getComponent()) {
      case 'index':
        return (
          <div className="outer-container">
            <Index
              translations={translations}
              online={online}
              onSelectSession={this.handleSelectSession}
              groupOptions={groupOptions}
              showCreatePerson={showCreatePerson}
              showNewSession={showNewSession}
            />
          </div>
        )
      case 'summary':
        return (
          <LoadSession sessionId={sessionId}>
            {session => (
              <div className="outer-container">
                <Summary
                  goToIndex={() => this.goTo('index')}
                  goToOrca={() => this.goTo('orca')}
                  session={session}
                  translations={translations}
                />
              </div>
            )}
          </LoadSession>
        )
      case 'grading':
        return (
          <LoadSession sessionId={sessionId}>
            {session => (
              <GradingFormsGroup
                directUploadsUrl={directUploadsUrl}
                incompleteMode={this.state.incompleteMode}
                online={online}
                session={session}
                translations={camelize(translations)}
              />
            )}
          </LoadSession>
        )
      case 'recording':
        return (
          <LoadSession sessionId={sessionId}>
            {session => <Recordings session={session} />}
          </LoadSession>
        )
      case 'timeline':
        return (
          <LoadSession sessionId={sessionId}>
            {session => (
              <div className="outer-container">
                <Timeline
                  activitiesData={session.activitiesData}
                  events={
                    session.gradingSessionTemplate.sessionTemplateTimelines
                  }
                />
              </div>
            )}
          </LoadSession>
        )
      default:
        return null
    }
  }

  getFooterButtons() {
    const { translations } = this.props
    const { view } = this.state

    switch (view) {
      case 'orca': {
        const { incompleteMode, sessionId } = this.state
        const session = this.props.sessions[sessionId]
        const allowIncomplete = session
          ? session.gradingSessionTemplate.allowIncomplete
          : false

        return (
          <>
            {allowIncomplete ? (
              <FooterButton
                dark={!incompleteMode}
                onClick={this.handleToggleIncompleteMode}
              >
                {translations.ubf.toggle_incomplete}
              </FooterButton>
            ) : null}
            <FooterButton onClick={this.handleSaveAndExit}>
              {translations.ubf.save_and_exit}
            </FooterButton>
          </>
        )
      }
      default:
        return null
    }
  }

  getLeftActiveComponentIndex() {
    const { view } = this.state
    if (view !== 'orca') return 0
    return this.props.flexlayout.tabSelected === 'timeline' ? 1 : 0
  }

  getPanes() {
    const { translations } = this.props
    const { view } = this.state

    if (view === 'orca') {
      const leftPane = [
        {
          type: 'tab',
          name: translate('layout.tabs.gradings', translations),
          component: 'grading',
          id: 'grading',
        },
        {
          type: 'tab',
          name: translate('layout.tabs.timeline', translations),
          component: 'timeline',
          id: 'timeline',
        },
      ]

      const rightPane = [
        {
          type: 'tab',
          name: translate('layout.tabs.recordings', translations),
          component: 'recording',
          id: 'recording',
        },
      ]

      return [leftPane, rightPane]
    }

    return [[{ type: 'tab', component: view }], null]
  }

  getSubtitle() {
    const { sessionId, view } = this.state

    if (view !== 'orca') return
    if (!sessionId) return

    const session = this.props.sessions[sessionId]

    if (!session) return

    return moment(session.gradedDate).format(UBF.globalDateFormat)
  }

  getTitle() {
    const { translations } = this.props
    const { view } = this.state

    switch (view) {
      case 'orca': {
        const { sessionId } = this.state
        const session = this.props.sessions[sessionId]
        return session ? session.gradingSessionTemplate.name : ''
      }
      case 'summary':
        return translations.titles.grading_session.main
      default:
        return translations.titles.grader_unsubmitted_grading_sessions.index
    }
  }

  handleBackAction() {
    const { view } = this.state

    if (view === 'orca') {
      this.handleSaveAndExit()
    } else if (view === 'summary') {
      this.goTo('index')
    } else {
      window.location.href = this.props.backUrl
    }
  }

  handleToggleIncompleteMode = () => {
    const { incompleteMode } = this.state
    this.setState({ incompleteMode: !incompleteMode })
  }

  handleSaveAndExit = () => {
    this.goTo('summary')
    this.props.saveSession(this.state.sessionId).then(newSessionId => {
      if (newSessionId) {
        // An offline-created session that's persisted to the server at this
        // point will receive an id from the database which replaces the local
        // offline-only id.
        this.setState({ sessionId: newSessionId })
      }
    })
  }

  handleSelectSession = sessionId => {
    this.setState({
      sessionId,
      view: 'summary',
    })
  }

  render() {
    const { flexlayout, online, pinEnabled, translations, userPin } = this.props
    const { loading, view } = this.state
    const [leftPane, rightPane] = this.getPanes()

    return (
      <>
        <OnlineWatcher />
        <NotificationModal notificationType="loading" visible={loading} />
        <OfflinePin
          translations={translations}
          userPin={userPin}
          pinEnabled={pinEnabled}
          online={online}
        />
        <QuantumLayout
          backAction={() => this.handleBackAction()}
          controlledFooterButtons={this.getFooterButtons()}
          title={this.getTitle()}
          subtitle={this.getSubtitle()}
          leftMaximized={flexlayout.maximized}
          leftPaneComponents={leftPane}
          rightPaneComponents={rightPane}
          customFactory={this.factory}
          multipleTab={view === 'orca'}
          leftActiveComponentIndex={this.getLeftActiveComponentIndex()}
          online={online}
        />
      </>
    )
  }
}

const mapStateToProps = state => {
  const online = onlineSelector(state)
  const sessions = sessionsSelector(state)
  const flexlayout = getFlexLayoutStore(state)
  return { online, sessions, flexlayout }
}

export default connect(mapStateToProps, { saveSession })(Module)
