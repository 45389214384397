// Copyright Northcote Technology Ltd
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Calendar from '../common/Calendar'
import SessionTemplatePicker from '../SessionTemplatePicker'
import { EntitiesSelector } from '../common/EntitiesSelector'
import Modal from '../common/Modal'
import NewPersonModal from './NewPersonModal'
import Tag from '../common/Tag'
import GroupPeopleSelector from '../common/GroupPeopleSelector'
import { personDisplay } from '../../src/lib/peopleHelper'
import {
  getGroupOptions,
  getPeople,
  getPersonOptions,
  getTemplateOptions,
} from '../../src/lib/idb/common'
import Button from '../Button'

export default class NewSessionModal extends Component {
  static propTypes = {
    translations: PropTypes.object.isRequired,
    people: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    defaultFirstName: PropTypes.string.isRequired,
    defaultLastName: PropTypes.string.isRequired,
    errors: PropTypes.object,
    showCreatePerson: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.state = {
      session: {
        date: null,
        newPeople: [],
      },
      addingPerson: false,
      newPerson: {},
    }
  }

  componentDidMount = async () => {
    const peopleParams = await getPersonOptions()
    const people = await getPeople()

    peopleParams.values = people.map(person => ({
      groups: person.groups.map(group => group.id),
      label: personDisplay(person),
      value: person.id.toString(),
    }))

    this.setState({
      templateParams: await getTemplateOptions(),
      groupParams: await getGroupOptions(),
      peopleParams,
    })
  }

  handleInputChange = event => {
    if (!event) return

    const { session } = this.state

    const target = event.target
    let value = target.type == 'checkbox' ? target.checked : target.value
    const name = target.name

    const newSession = {
      ...session,
      [name]: value,
    }

    if (name == 'group_id') {
      newSession.person_ids = []
      newSession.newPeople = []
    }

    this.setState({ session: newSession })
    this.props.onUpdate(newSession)
  }

  addNewPerson = () => {
    this.setState({ addingPerson: true })
  }

  saveNewPerson = () => {
    const { people, translations } = this.props
    const { newPerson } = this.state
    const { newPeople } = this.state.session
    const t =
      translations.activerecord.errors.models.grading_session.attributes.people

    let newPersonErrors = {}
    if (!newPerson || !newPerson.identifier)
      newPersonErrors.identifier = t.required_identifier
    else if (
      people.find(p => p.identifier == newPerson.identifier) ||
      newPeople.find(p => p.identifier == newPerson.identifier)
    )
      newPersonErrors.identifier = t.identifier_used

    if (Object.keys(newPersonErrors).length == 0) {
      this.setState({ addingPerson: false, newPersonErrors: {} })
      this.createNewPerson()
    } else {
      this.setState({ newPersonErrors: newPersonErrors })
    }
  }

  createNewPerson = () => {
    const { defaultFirstName, defaultLastName } = this.props
    const { session } = this.state
    let newPerson = this.state.newPerson
    let newPeople = this.state.session.newPeople
    let newSession = { ...session }

    if (newPerson.firstName == '') newPerson.firstName = defaultFirstName
    if (newPerson.lastName == '') newPerson.lastName = defaultLastName
    newPeople.push(newPerson)
    newSession.newPeople = newPeople

    this.setState({ newPerson: {}, session: newSession })
    this.props.onUpdate(newSession)
  }

  closeNewPerson = () => {
    this.setState({ addingPerson: false, newPerson: {} })
  }

  updateNewPerson = person => {
    this.setState({ newPerson: person })
  }

  removePerson = identifier => {
    const newPeople = this.state.session.newPeople.filter(
      p => p.identifier != identifier
    )
    let newSession = { ...this.state.session }
    newSession.newPeople = newPeople

    this.setState({ session: newSession })
  }

  validSessionTemplates(sessionTemplates, date) {
    if (!date) return sessionTemplates

    return sessionTemplates.filter(template => {
      return !template.obsolescenceDate || template.obsolescenceDate >= date
    })
  }

  render() {
    const { showCreatePerson, translations, errors } = this.props
    const {
      session,
      addingPerson,
      newPersonErrors,
      templateParams,
      peopleParams,
      groupParams,
    } = this.state
    const showCreatePersonButton =
      showCreatePerson && (!UBF.personGroupsMandatory || session.group_id)

    return (
      <div className="sessions__index--modal">
        <Modal
          title={translations.ubf.new_model.person}
          visible={addingPerson}
          buttonSave={true}
          onSaveModal={this.saveNewPerson}
          onCloseModal={this.closeNewPerson}
          translations={translations}
          zIndex={200}
        >
          {addingPerson ? (
            <NewPersonModal
              translations={translations}
              onUpdate={this.updateNewPerson}
              errors={newPersonErrors}
              groupParams={groupParams}
              currentGroupId={session.group_id}
            />
          ) : null}
        </Modal>

        <div className="quantum-layout__field">
          <Calendar
            name={'date'}
            placeholder={translations.ubf.date}
            selected={session ? session.date : ''}
            onChange={this.handleInputChange}
            translations={translations}
          />
          {errors && errors.date ? (
            <div className="error">
              {
                translations.activerecord.errors.models.grading_session
                  .mandatory.date
              }
            </div>
          ) : null}
        </div>

        {templateParams?.sessionTemplates ? (
          <div className="quantum-layout__field">
            <SessionTemplatePicker
              sessionTemplates={this.validSessionTemplates(
                templateParams.sessionTemplates,
                session.date
              )}
              sessionTemplateName={
                translations.activerecord.attributes.sessions_for_grader
                  .template
              }
              sessionTemplateNoResultsText={templateParams.noResultsText}
              sessionTemplatePlaceholderText={templateParams.placeholder}
              sessionTemplateLabel={''}
              styleVersion={2}
              newUi={true}
              onUpdate={this.handleInputChange}
              mainClass={'quantum-layout__field'}
              selectClass={'quantum-layout__select'}
              prefixClass={'select'}
            />

            {errors && errors.template ? (
              <div className="error">
                {
                  translations.activerecord.errors.models.grading_session
                    .mandatory.template
                }
              </div>
            ) : null}
          </div>
        ) : null}

        {peopleParams?.values &&
          (UBF.personGroupsMandatory ? (
            <GroupPeopleSelector
              groupOptions={groupParams}
              peopleOptions={peopleParams}
              onUpdate={this.handleInputChange}
            />
          ) : (
            <EntitiesSelector
              async={true}
              currentValues={[]}
              info={peopleParams}
              mainClass="quantum-layout__field"
              onUpdate={this.handleInputChange}
              prefixClass="select"
            />
          ))}

        <div className="quantum-layout__field">
          {session.newPeople.map(person => {
            return (
              <Tag
                key={person.identifier}
                text={personDisplay(person)}
                identifier={person.identifier}
                removable={true}
                onRemove={this.removePerson}
              />
            )
          })}

          {errors && errors.people ? (
            <div className="error">
              {
                translations.activerecord.errors.models.grading_session
                  .mandatory.people
              }
            </div>
          ) : null}
        </div>

        {showCreatePersonButton && (
          <div className="right">
            <Button onClick={this.addNewPerson}>
              {translations.ubf.new_model.person}
            </Button>
          </div>
        )}
      </div>
    )
  }
}
